import React, { useEffect } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Grid, List as FixedSizeList, WindowScroller } from 'react-virtualized';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
import { connect } from 'react-redux';
import { SearchInput, SortDropdown, Icon } from '@studio/legacy-components';
import { Shape, hasMatchingSegment } from '@studio/conditions';
import { Filter, GroupedFiltersView } from 'next/components/Filter';
import { Shape as FlowShape } from 'next/entities/flows';
import { Shape as TagsShape } from 'next/entities/tags';
import { Shape as CreatorShape } from 'next/entities/users';
import { selectRules } from 'next/entities/rules';
import { Shape as SegmentShape } from 'next/entities/segments';
import { AppShape, MOBILE_PLATFORM_ICONS } from 'next/entities/apps';
import {
  MOBILE_DEFAULT_TYPE,
  MOBILE_EMBED_TYPE,
} from 'next/entities/experiences';
import { loadFilters, saveFilter, saveFilterGroup } from 'next/lib/filter';
import {
  Control,
  ControlSeparator,
  Controls,
  List,
  NoResults,
  ViewToggle,
} from 'next/components/Listing';
import { useControls } from './use-controls';
import Empty from './Empty';
import FlowCard from './FlowCard';

const createTagsOptions = (tags, currentOptions = {}) =>
  tags.reduce((acc, tag) => {
    acc[tag.id] = {
      label: tag.name,
      enabled: currentOptions[tag.id] ? currentOptions[tag.id].enabled : false,
      filterFn: experience =>
        Array.isArray(experience.tagIds) && experience.tagIds.includes(tag.id),
    };
    return acc;
  }, {});

const sortByName = collection =>
  [...collection.filter(ele => Boolean(ele.name))].sort((a, b) =>
    a.name.localeCompare(b.name, undefined, {
      numeric: true,
    })
  );

const createCreatorsOptions = (creators, currentOptions = {}) =>
  creators.reduce((acc, creator) => {
    acc[creator.id] = {
      label: creator.name,
      enabled: currentOptions[creator.id]
        ? currentOptions[creator.id].enabled
        : false,
      filterFn: experience => experience.createdBy === creator.id,
    };
    return acc;
  }, {});

const sortDomains = domains =>
  [...domains].sort((a, b) => a.localeCompare(b, undefined, { numeric: true }));

const createDomainOptions = (domainAssociations, currentOptions = {}) => {
  const sortedDomains = sortDomains(Object.keys(domainAssociations));
  return sortedDomains.reduce((acc, domain) => {
    acc[domain] = {
      label: domain,
      enabled: currentOptions[domain] ? currentOptions[domain].enabled : false,
      filterFn: experience =>
        domainAssociations[domain]?.includes(experience.id),
    };
    return acc;
  }, {});
};

export function PageList({
  creators = {},
  domainAssociations = {},
  flows,
  segments = {},
  rules = {},
  tags = {},
  apps = {},
}) {
  const hasMobileFlows =
    flows &&
    Object.values(flows).some(
      flow =>
        flow.type === MOBILE_DEFAULT_TYPE || flow.type === MOBILE_EMBED_TYPE
    );

  const savedFilter = loadFilters('flows');

  const {
    processed,
    search,
    sort,
    view,
    filters,
    updateFilters,
    handleFilterChange,
    handleFilterClear,
  } = useControls(
    { data: flows },
    {
      ...(hasMobileFlows && {
        app: {
          name: 'App',
          options: {
            ...sortByName(Object.values(apps)).reduce((acc, app) => {
              acc[app.id] = {
                label: app.name,
                icon: <Icon icon={MOBILE_PLATFORM_ICONS[app.platform]} />,
                enabled: savedFilter.app?.[app.id]?.enabled ?? false,
                filterFn: experience => experience.appId === app.id,
              };
              return acc;
            }, {}),
            web: {
              label: 'Web app',
              icon: <Icon icon="desktop" />,
              enabled: savedFilter.app?.web?.enabled ?? false,
              filterFn: experience => !experience.appId,
            },
          },
        },
      }),
      flow_status: {
        name: 'Status',
        options: {
          draft: {
            label: 'Draft',
            enabled: savedFilter.flow_status?.draft?.enabled ?? false,
            filterFn: item => !item.published && item.state === 'DRAFT',
          },
          live: {
            label: 'Live',
            enabled: savedFilter.flow_status?.live?.enabled ?? false,
            filterFn: item => item.published && item.state === 'DRAFT',
          },
          archived: {
            label: 'Include archived',
            enabled: savedFilter.flow_status?.archived?.enabled ?? false,
            separated: true,
            defaultHidden: true,
            filterFn: item => item.state === 'ARCHIVED',
          },
        },
      },
      tags: {
        name: 'Tag',
        options: createTagsOptions(
          sortByName(Object.values(tags)),
          savedFilter.tags
        ),
      },
      segments: {
        name: 'Segment',
        options: sortByName(Object.values(segments)).reduce((acc, segment) => {
          acc[segment.id] = {
            label: segment.name,
            enabled: savedFilter.segments?.[segment.id]?.enabled ?? false,
            filterFn: experience =>
              hasMatchingSegment(rules[experience.id]?.conditions, segment.id),
          };
          return acc;
        }, {}),
      },
      creator: {
        name: 'Creator',
        options: createCreatorsOptions(
          sortByName(Object.values(creators)),
          savedFilter.creator
        ),
      },
      domains: {
        name: 'Domain',
        options: createDomainOptions(domainAssociations, savedFilter.domains),
      },
    }
  );

  const onFilterChange = (group, option, isEnabled) => {
    saveFilter('flows', group, option, isEnabled);
    handleFilterChange(group, option, isEnabled);
  };

  const onFilterClear = group => {
    saveFilterGroup('flows', group, false);
    handleFilterClear(group);
  };

  useEffect(() => {
    updateFilters(currentFilters => {
      const newTags = Object.values(tags);
      const newDomainAssociations = domainAssociations;
      return {
        ...currentFilters,
        domains: {
          ...currentFilters?.domains,
          options: {
            ...createDomainOptions(
              newDomainAssociations,
              currentFilters.domains.options
            ),
          },
        },
        tags: {
          ...currentFilters.tags,
          options: {
            ...createTagsOptions(
              sortByName(newTags),
              currentFilters.tags.options
            ),
          },
        },
      };
    });
  }, [domainAssociations, tags, updateFilters]);

  if (isEmpty(flows)) {
    return <Empty />;
  }
  return (
    <>
      <Controls>
        <Control>
          <SearchInput
            onChange={search.onChange}
            placeholder="Search by Flow name"
            value={search.value}
          />
        </Control>

        <Control>
          <Filter
            filters={filters}
            onChange={onFilterChange}
            onClear={onFilterClear}
            view={GroupedFiltersView}
          />
        </Control>

        <ControlSeparator />

        <Control>
          <SortDropdown
            onChange={sort.onChange}
            options={sort.options}
            value={sort.value}
          />
        </Control>

        <Control>
          <ViewToggle onChange={view.onChange} value={view.value} />
        </Control>
      </Controls>

      {isEmpty(processed) && (
        <NoResults>No results found. Please try different filters.</NoResults>
      )}

      {!isEmpty(processed) && (
        <List>
          <WindowScroller>
            {({ height, isScrolling, onChildScroll, scrollTop }) => (
              <AutoSizer disableHeight>
                {({ width }) => {
                  const numColumns = width < 1000 ? 2 : 3;

                  if (view.value === 'list') {
                    return (
                      <FixedSizeList
                        autoHeight
                        height={height}
                        rowCount={processed.length}
                        rowHeight={136}
                        // eslint-disable-next-line react/no-unstable-nested-components
                        rowRenderer={({ index, style }) => {
                          const flow = processed[index];
                          return (
                            <FlowCard
                              style={{
                                ...style,
                                bottom: (style.bottom || 0) + 16,
                              }}
                              key={flow.id}
                              flow={flow}
                              view={view.value}
                              deferred={isScrolling}
                            />
                          );
                        }}
                        isScrolling={isScrolling}
                        onScroll={onChildScroll}
                        scrollTop={scrollTop}
                        width={width}
                        processed={processed}
                      />
                    );
                  }

                  return (
                    <Grid
                      autoHeight
                      // eslint-disable-next-line react/no-unstable-nested-components
                      cellRenderer={({ columnIndex, rowIndex, style }) => {
                        const index = rowIndex * numColumns + columnIndex;
                        if (index >= processed.length) {
                          return null;
                        }
                        const flow = processed[index];
                        const isFirstColumn = columnIndex === 0;
                        return (
                          <FlowCard
                            style={{
                              ...style,
                              left: isFirstColumn
                                ? style.left
                                : style.left + 16,
                              width: isFirstColumn
                                ? style.width
                                : style.width - 16,
                              top: style.top + 16,
                              height: style.height - 16,
                            }}
                            key={flow.id}
                            flow={flow}
                            view={view.value}
                            deferred={isScrolling}
                          />
                        );
                      }}
                      columnWidth={width / numColumns}
                      columnCount={numColumns}
                      height={height}
                      isScrolling={isScrolling}
                      onScroll={onChildScroll}
                      rowCount={Math.ceil(processed.length / numColumns)}
                      rowHeight={296}
                      scrollTop={scrollTop}
                      width={width}
                    />
                  );
                }}
              </AutoSizer>
            )}
          </WindowScroller>
        </List>
      )}
    </>
  );
}

const mapStateToProps = state => ({
  rules: selectRules(state),
});

export default connect(mapStateToProps)(PageList);

PageList.propTypes = {
  creators: PropTypes.objectOf(CreatorShape),
  domainAssociations: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  flows: PropTypes.objectOf(FlowShape),
  rules: PropTypes.objectOf(Shape),
  segments: PropTypes.objectOf(SegmentShape),
  tags: PropTypes.objectOf(TagsShape),
  apps: PropTypes.objectOf(AppShape),
};
