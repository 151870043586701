import React, { useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { CPanel, Text } from '@appcues/component-library';
import { Link } from '@appcues/sonar';
import { getPropertyDisplayName } from 'utils';
import Loader from 'components/Common/Loader';
import Table from 'components/Common/UI/Table';
import SelectProfileAttribute from 'components/Common/SelectProfileAttribute';
import { updateAccountMeta } from 'actions/account/meta';
import { selectAccountMeta } from 'reducers/account/meta';
import {
  selectUserProfileAttributes,
  callApi as fetchUserProfileAttributes,
} from 'entities/user-profiles-attributes';
import { asPromised } from 'utils/as-promised';
import UserSampleTableUserCell from './UserSampleTableUserCell';

const LinkToAll = styled(Link)`
  margin-top: var(--spacing-x-large);
  margin-left: -10px;
`;

export function UserSampleTable({
  title,
  timestampHeader = 'Timestamp',
  emptyStateText = 'No users seen yet',
  onSave,
  userSample,
  userNameField,
  userProfileIdentifier,
  linkToAllUsers = '',
  onLoad,
  profileAttribute,
}) {
  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const handleProfileAttributeColumnChange = field => {
    onSave({ userNameField: field.value });
  };

  const userSampleRows =
    userSample.meta.synced && !userSample.meta.errored ? userSample.data : [];
  const loaded = userSample.meta.synced;
  const usersExist =
    userSample.meta.synced &&
    !userSample.meta.errored &&
    userSample.data.length > 0;

  const columns = [
    {
      Header: () => {
        return (
          <SelectProfileAttribute
            value={userNameField}
            onChange={handleProfileAttributeColumnChange}
          />
        );
      },
      accessor: 'user_id',
      Cell: ({ value }) => {
        return (
          <UserSampleTableUserCell
            userId={value}
            profileAttributeColumn={userNameField}
            renderAsLink={false}
            profileAttribute={profileAttribute}
          />
        );
      },
    },
    {
      Header: timestampHeader,
      accessor: 'timestamp',
      sortable: true,
      Cell: ({ value }) =>
        value ? moment.utc(value).local().format('lll') : '-',
    },
  ];

  columns.unshift({
    Header: getPropertyDisplayName(userProfileIdentifier),
    id: 'identifier',
    accessor: 'user_id',
    Cell: ({ value }) => {
      return (
        <UserSampleTableUserCell
          userId={value}
          profileAttributeColumn={userProfileIdentifier}
          renderAsLink
        />
      );
    },
  });

  return (
    <CPanel>
      {title}
      {loaded && usersExist && (
        <>
          <Table
            panel
            defaultSortColumn="timestamp"
            containerStyles={{ marginTop: 20 }}
            columns={columns}
            data={userSampleRows}
          />
          {linkToAllUsers ? (
            <LinkToAll href={linkToAllUsers}>See all users</LinkToAll>
          ) : null}
        </>
      )}
      {loaded && !usersExist && (
        <Text type="tertiary" marginY={32}>
          {emptyStateText}
        </Text>
      )}
      {!loaded && <Loader margin="2rem" />}
    </CPanel>
  );
}

UserSampleTable.propTypes = {
  userSample: PropTypes.shape({
    meta: PropTypes.shape({
      fetching: PropTypes.bool,
      synced: PropTypes.bool,
      errored: PropTypes.bool,
    }),
    data: PropTypes.arrayOf(
      PropTypes.shape({
        user_id: PropTypes.string,
        timestamp: PropTypes.string,
      })
    ),
  }),
  title: PropTypes.element,
  timestampHeader: PropTypes.string,
  emptyStateText: PropTypes.string,
  linkToAllUsers: PropTypes.string,
  onSave: PropTypes.func,
  onLoad: PropTypes.func,
  profileAttribute: PropTypes.object,
};

function mapStateToProps(state) {
  const accountMeta = selectAccountMeta(state);
  const { userNameField, userProfileIdentifier } = accountMeta;
  const userProfileAttributes = selectUserProfileAttributes(state) || [];
  const profileAttribute = userProfileAttributes.find(
    attr => attr.name === userNameField
  );

  return {
    userNameField,
    userProfileIdentifier: userProfileIdentifier || 'user_id',
    profileAttribute,
  };
}

const mapDispatchToProps = dispatch => ({
  onSave: changes => dispatch(updateAccountMeta(changes)),
  onLoad: () => asPromised(dispatch, fetchUserProfileAttributes()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserSampleTable);
