import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { selectAccountFeature } from 'reducers/account/features';

import Profile from './Profile';
import { Audiences } from './Audiences';
import SegmentListPage from './SegmentList/Page';
import UserListPage from './UserList/Page';

const Audience = ({
  hasS5UserProfile,
  hasS5SegmentsList,
  hasNextUsersPage,
}) => (
  <Switch>
    {/* 
        These are placeholder routes for the audience,
        the components for the routes are currently under development 
        and once merged should be set as component prop in the routes bellow
     */}
    {hasS5UserProfile && (
      <Redirect
        from="/audience/users/:userId/properties"
        to="/audience/users-v2/:userId/properties"
      />
    )}
    {/*
     * If S5_SEGMENTS_LIST is disabled, we need to
     * handle the routing out of the Audiences component to avoid the legacy
     * layout.
     */}
    {!hasS5SegmentsList && (
      <Route path="/audience/segments" exact component={SegmentListPage} />
    )}
    {/*
     * If NEXT_USERS_PAGE is disabled, we need to
     * handle the routing out of the Audiences component to avoid the legacy
     * layout.
     */}
    {!hasNextUsersPage && (
      <Route path="/audience/users" exact component={UserListPage} />
    )}
    <Route path="/audience/users/:userId" component={Profile} />
    <Route path="/audience" component={Audiences} />
  </Switch>
);

Audience.propTypes = {
  hasS5UserProfile: PropTypes.bool,
  hasS5SegmentsList: PropTypes.bool,
  hasNextUsersPage: PropTypes.bool,
};

const mapStateToProps = state => ({
  hasS5UserProfile: selectAccountFeature(state, 'S5_USER_PROFILE'),
  hasS5SegmentsList: selectAccountFeature(state, 'S5_SEGMENTS_LIST'),
  hasNextUsersPage: selectAccountFeature(state, 'NEXT_USERS_PAGE'),
});

export default connect(mapStateToProps)(Audience);
