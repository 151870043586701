import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEmpty from 'lodash.isempty';
import { withFormik } from 'formik';
import { object, string } from 'yup';
import { ErrorMsg, ExternalLink, Input } from '@studio/legacy-components';
import { isTrialAccount } from '@studio/navigation';
import { Select, SelectOption } from '@appcues/sonar';
import {
  selectUser,
  readUser,
  Shape as UserShape,
  update,
} from 'next/entities/user';
import { asRequest } from 'next/entities/requests';
import { WELCOME_TRIAL_PAGES, selectFeature } from 'next/entities/features';
import {
  generateHubspotPayload,
  getHubspotCookieValue,
  postToHubspot,
} from 'next/lib/hubspot';
import { history } from 'next/lib/history';
import SignOut from 'next/components/SignOut';
import { selectTrialStatus } from 'next/entities/trial-status';
import { selectAccount } from 'next/entities/account';
import { JOB_DROPDOWN_OPTIONS } from 'constants/inviteModal';
import useConversion from './use-conversion';
import Logo from './Logo';
import {
  CardNegativeNotice,
  CompleteSignupButton,
  FooterText,
  LinkSection,
  SignupCard,
  SignupContainer,
  SignupForm,
  SignupHeader,
  SignupLabel,
  Wrapper,
} from './styled';

const TERMS_OF_SVC_URL = 'https://www.appcues.com/terms';
const PRIVACY_POLICY_URL = 'https://www.appcues.com/privacy';

/**
 * Track signup event on Segment
 * @param {*} userId
 * @param {*} accountId
 */
export const trackSignupOnSegmentFunc = (userId, accountId) => {
  if (window.analytics && window.analytics.track) {
    window.analytics.track({
      userId,
      event: 'Signed up',
      properties: {
        label: 'Product',
        category: 'Appcues',
        accountId,
      },
    });
  }
};

/**
 * Track signup event on Hubspot
 * @param {*} user
 */
export const trackSignupOnHubspotFunc = async user => {
  const { email, firstName, lastName, howDidFind } = user ?? {};
  const hubspotutk = getHubspotCookieValue();
  const hubspotFormData = generateHubspotPayload({
    email,
    firstName,
    lastName,
    hutk: hubspotutk,
    trial__how_did_you_hear_about_us_: howDidFind,
  });

  await postToHubspot(
    /* global HUBSPOT_V3_SIGNUP_FORM_ID */
    HUBSPOT_V3_SIGNUP_FORM_ID,
    hubspotFormData
  );
};

export const SignupInfo = ({
  isTrial,
  accountId,
  hasUserWelcome,
  errors,
  touched,
  handleBlur,
  handleChange,
  onSubmit,
  trackSignupOnHubspot = trackSignupOnHubspotFunc,
  trackSignupOnSegment = trackSignupOnSegmentFunc,
  user,
  userStatus,
  values,
  isWelcomeTrialPagesEnabled,
}) => {
  const { loading, error } = userStatus ?? {};
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const { track: trackConversion, scriptsLoaded } = useConversion();

  const handleSubmit = async e => {
    e.preventDefault();
    setIsSubmitting(true);
    const { firstName, lastName, job, howDidFind } = values ?? {};
    const fullname = `${firstName} ${lastName}`;
    const res = (await onSubmit(user?.id, { fullname, job })) ?? {};
    const { signedUp } = res ?? {};

    if (!signedUp) {
      setIsSubmitting(false);
      return;
    }

    if (isTrial) {
      trackSignupOnSegment(user?.id, accountId);
      await trackSignupOnHubspot({
        email: user?.email,
        firstName,
        lastName,
        howDidFind,
      });

      if (scriptsLoaded) await trackConversion();
    }

    history.push(isTrial && isWelcomeTrialPagesEnabled ? '/usage' : '/');
  };

  const { email, firstName, lastName, job, howDidFind } = values ?? {};

  const isDisabled =
    loading ||
    !isEmpty(errors) ||
    !firstName ||
    !lastName ||
    !job ||
    user?.signedUp;

  return (
    <Wrapper>
      <SignupContainer>
        <SignupCard>
          <Logo />
          <SignupHeader>You're almost done</SignupHeader>
          <SignupForm onSubmit={handleSubmit}>
            {error && (
              <CardNegativeNotice>
                We're sorry, something went wrong on our end. Try again.
              </CardNegativeNotice>
            )}
            <SignupLabel htmlFor="email">
              Work email
              <Input
                id="email"
                type="email"
                name="email"
                disabled
                error={!!errors?.email}
                value={email}
              />
            </SignupLabel>
            <SignupLabel htmlFor="firstName">
              First name
              <Input
                autoFocus
                id="firstName"
                type="text"
                name="firstName"
                error={!!touched?.firstName && !!errors?.firstName}
                onBlur={handleBlur}
                onChange={handleChange}
                value={firstName}
              />
              {!!touched?.firstName && errors?.firstName && (
                <ErrorMsg>{errors?.firstName}</ErrorMsg>
              )}
            </SignupLabel>
            <SignupLabel htmlFor="lastName">
              Last name
              <Input
                id="lastName"
                type="text"
                name="lastName"
                error={!!touched?.lastName && !!errors?.lastName}
                onBlur={handleBlur}
                onChange={handleChange}
                value={lastName}
              />
              {!!touched?.lastName && errors?.lastName && (
                <ErrorMsg>{errors?.lastName}</ErrorMsg>
              )}
            </SignupLabel>
            <SignupLabel htmlFor="job">
              Your role
              <Select
                placeholder="Select one"
                width="full-width"
                id="job"
                name="job"
                value={job}
                error={!!touched?.job && !!errors?.job}
                onOpenChange={() => {
                  // since we don't have access to onBlur from sonar/select we
                  // should use the open handle to control the Formik touched state
                  handleBlur({ target: { name: 'job' } });
                }}
                onValueChange={value => {
                  handleChange({ target: { name: 'job', value } });
                }}
              >
                {JOB_DROPDOWN_OPTIONS.map(({ label, value }) => (
                  <SelectOption key={value} label={label} value={value} />
                ))}
              </Select>
              {!!touched?.job && errors?.job && (
                <ErrorMsg>{errors?.job}</ErrorMsg>
              )}
            </SignupLabel>

            {isTrial ? (
              <SignupLabel htmlFor="howDidFind">
                How did you hear about us?
                <Input
                  id="howDidFind"
                  type="text"
                  name="howDidFind"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={howDidFind}
                />
              </SignupLabel>
            ) : null}

            <CompleteSignupButton
              type="submit"
              fluid
              disabled={isDisabled || isSubmitting}
              loading={loading || isSubmitting}
            >
              {isTrial && hasUserWelcome ? 'Next' : 'Complete sign-up'}
            </CompleteSignupButton>
          </SignupForm>
        </SignupCard>
        <LinkSection>
          <FooterText>
            By signing up for Appcues, you agree to our{' '}
            <ExternalLink href={TERMS_OF_SVC_URL}>
              Terms of Service
            </ExternalLink>{' '}
            and{' '}
            <ExternalLink href={PRIVACY_POLICY_URL}>
              Privacy Policy
            </ExternalLink>
            .
          </FooterText>
        </LinkSection>
      </SignupContainer>
      <SignOut />
    </Wrapper>
  );
};

const FormShape = PropTypes.shape({
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  job: PropTypes.string,
});

SignupInfo.propTypes = {
  isTrial: PropTypes.bool,
  accountId: PropTypes.string,
  user: UserShape,
  userStatus: asRequest(UserShape),
  hasUserWelcome: PropTypes.bool,
  // formik HOC props
  errors: FormShape,
  touched: PropTypes.shape({
    firstName: PropTypes.bool,
    lastName: PropTypes.bool,
    job: PropTypes.bool,
  }),
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  onSubmit: PropTypes.func,
  trackSignupOnHubspot: PropTypes.func,
  trackSignupOnSegment: PropTypes.func,
  values: FormShape,
  isWelcomeTrialPagesEnabled: PropTypes.bool,
};

const enhanceForm = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ user: { email } = {} }) => ({
    email,
    firstName: '',
    lastName: '',
    job: '',
    howDidFind: '',
  }),
  mapPropsToTouched: () => ({
    firstName: false,
    lastName: false,
    job: false,
  }),
  validationSchema: object().shape({
    firstName: string().required('First name is required.'),
    lastName: string().required('Last name is required.'),
    job: string().required('Job is required.'),
  }),
});

export const SignupInfoForm = enhanceForm(SignupInfo);

const mapStateToProps = state => {
  const user = selectUser(state);
  const account = selectAccount(state);
  const trialStatus = selectTrialStatus(state);
  const isWelcomeTrialPagesEnabled = selectFeature(state, WELCOME_TRIAL_PAGES);
  return {
    isTrial: isTrialAccount(account, trialStatus),
    accountId: account?.id,
    user,
    userStatus: readUser(state, user?.id),
    isWelcomeTrialPagesEnabled,
  };
};

const mapDispatchToProps = {
  onSubmit: update,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignupInfoForm);
