/**
 * This file is a copy of the format-property.ts file in studio5.
 * It is a temporary solution to allow the studio-classic codebase to
 * use the format-property.ts file from studio5.
 */

export const PropertyType = {
  Boolean: 'BOOLEAN',
  Date: 'DATE',
  Number: 'NUMBER',
  String: 'STRING',
};

export const formatNumber = (value, options) => {
  return new Intl.NumberFormat(undefined, {
    ...options,
  }).format(value);
};

export const formatTime = value => {
  return new Intl.DateTimeFormat(undefined, {
    hour: 'numeric',
    minute: 'numeric',
  }).format(value);
};

export const formatDateTime = value => {
  const date = new Intl.DateTimeFormat(undefined, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }).format(value);

  const time = formatTime(value);
  return `${date} ${time}`;
};

const isISOString = dateStr => {
  const isoRegex =
    /^\d{4}-\d{2}-\d{2}(?:T\d{2}:\d{2}:\d{2}(?:\.\d{1,3})?(?:Z|[+-]\d{2}:?\d{2})?)?$/;
  return isoRegex.test(dateStr);
};

const isUnixTimestampInSeconds = dateStr => {
  // Unix timestamps in seconds can be 9-10 digits (before year 2001 they were 9 digits)
  const unixRegex = /^\d{9,10}$/;
  return unixRegex.test(dateStr);
};

const isUnixTimestampInMilliseconds = dateStr => {
  // Milliseconds should be 13 digits for modern dates
  const unixRegex = /^\d{11,13}$/;
  return unixRegex.test(dateStr);
};

const formatPropertyBoolean = value => {
  const isValidBoolean =
    typeof value === 'boolean' || value === 'true' || value === 'false';

  return {
    rawValue: value,
    formattedValue: String(value),
    hasError: !isValidBoolean,
  };
};

const formatPropertyNumber = value => {
  const isValidNumber =
    typeof value === 'number' ||
    typeof value === 'bigint' ||
    (!Number.isNaN(Number(value)) && Number.isFinite(Number(value)));

  return {
    rawValue: value,
    formattedValue: isValidNumber ? formatNumber(Number(value)) : String(value),
    hasError: !isValidNumber,
  };
};

const formatPropertyDate = value => {
  let date = null;

  if (isUnixTimestampInSeconds(String(value))) {
    date = new Date(Number(value) * 1000);
  } else if (isUnixTimestampInMilliseconds(String(value))) {
    date = new Date(Number(value));
  } else if (isISOString(String(value))) {
    date = new Date(String(value));
  }

  return {
    rawValue: value,
    formattedValue: date ? formatDateTime(date) : String(value),
    hasError: !date,
  };
};

/**
 * Formats a property value based on the property type.
 *
 * Property values set as strings should appear as strings anywhere in Appcues
 * String format: [no special handling needed]
 *
 * Property values set as booleans should appear as booleans anywhere in Appcues
 * Boolean format: [no special handling needed]
 *
 * Property values set as numbers should appear as numbers anywhere in Appcues
 * Number format:
 * If the value doesn’t have any points beyond the decimal point, we’ll display as an integer
 * If the value has any points beyond the decimal point, we’ll display up to two numbers after the decimal
 *
 * Property values set as dates should appear as dates anywhere in Appcues, etc.
 * Date format: Nov 1, 2024 (first three letters of month, a 1- or 2- digit numerical day, then a 4-digit numerical year)
 */
export const formatProperty = (value, propertyType) => {
  if (propertyType === PropertyType.Boolean) {
    return formatPropertyBoolean(value);
  }

  if (propertyType === PropertyType.Number) {
    return formatPropertyNumber(value);
  }

  if (propertyType === PropertyType.Date) {
    return formatPropertyDate(String(value));
  }

  return {
    rawValue: value,
    formattedValue: String(value),
    hasError: false,
  };
};
