import React, { useEffect, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';

import {
  Thead,
  Th,
  Tr,
  Td,
  SearchInput,
  H3,
  StarCheckbox,
  Panel,
  PanelHeader,
  PanelTitle,
  PanelActions,
  Spinner,
  ExternalLink,
} from '@studio/legacy-components';
import { Filter } from 'next/components/Filter';
import useFilters from 'next/hooks/use-filter';
import useTitle from 'next/hooks/use-title';
import NoData from 'assets/images/no-data.svg';
import NoResults from 'assets/images/no-results.svg';
import {
  LoaderWrapper,
  NoResultsMessage,
  NoResultsMessageEmphasis,
  TableWrapper,
} from 'components/Audience/styled';
import { ScrollableTbody } from 'components/Audience/Profile/styled';
import { fetchUserProfile } from 'actions/userProfiles';
import { selectUserProfileAttributes } from 'selectors/profile-attributes';
import {
  selectUserProfileAttributes as selectProfileAttributes,
  callApi as fetchUserProfileAttributes,
} from 'entities/user-profiles-attributes';
import { upsert } from 'entities/profile-attribute-labels';
import { PropertyValue } from './PropertyValue';
import {
  PropertiesTable,
  PropertiesRow,
  StarredIcon,
  NotStarredIcon,
  FilterOption,
} from './styled';

const PROPERTIES_FILTERS = {
  starreds: {
    name: 'Starred',
    options: {
      starred: {
        enabled: false,
        label: (
          <FilterOption aria-label="filter starred">
            Starred <StarredIcon />
          </FilterOption>
        ),
        filterFn: ({ isStarred }) => isStarred,
      },
      notstarred: {
        enabled: false,
        label: (
          <FilterOption aria-label="filter not starred">
            Not Starred <NotStarredIcon />
          </FilterOption>
        ),
        filterFn: ({ isStarred }) => !isStarred,
      },
    },
  },
};

const stringifyValue = value =>
  (typeof value === 'string' ? value : JSON.stringify(value)) || '-';

export function Properties({
  onLoad,
  userProperties,
  onStarredChange,
  profileAttributes = [],
}) {
  useTitle('Properties | Users | Appcues');
  const [query, setQuery] = useState('');
  const [sortConfig, setSortConfig] = useState({
    order: 'asc',
    column: 'label',
  });

  const isLoading = !userProperties;
  const { filters, filterFunction, handleFilterChange, handleFilterClear } =
    useFilters(PROPERTIES_FILTERS);

  const sortFn = useCallback(
    (a = '', b = '') => {
      const { order, column } = sortConfig;
      const columnA = a[column];
      const columnB = b[column];
      const columnAValue =
        typeof columnA === 'string' ? columnA : JSON.stringify(columnA) || '-';
      const columnBValue =
        typeof columnB === 'string' ? columnB : JSON.stringify(columnB) || '-';

      if (columnAValue !== columnBValue) {
        if (order === 'asc') {
          return columnAValue.localeCompare(columnBValue, undefined, {
            numeric: true,
          });
        }
        return columnBValue.localeCompare(columnAValue, undefined, {
          numeric: true,
        });
      }

      return String(a.label).localeCompare(String(b.label), undefined, {
        numeric: true,
      });
    },
    [sortConfig]
  );

  const filteredProperties = useMemo(() => {
    return userProperties
      ?.filter(filterFunction)
      .filter(({ name, label }) =>
        (label ?? name).toUpperCase().includes(query.toUpperCase())
      )
      .sort(sortFn);
  }, [userProperties, filterFunction, sortFn, query]);

  const hasNoData = userProperties?.length === 0;
  const hasNoResults = !hasNoData && filteredProperties?.length === 0;

  useEffect(() => {
    onLoad?.();
  }, [onLoad]);

  const handleColumnClick = column => {
    setSortConfig(current => {
      if (column === sortConfig.column) {
        return {
          ...current,
          order: current.order === 'asc' ? 'desc' : 'asc',
        };
      }
      return {
        order: 'asc',
        column,
      };
    });
  };

  return (
    <Panel>
      <PanelHeader>
        <PanelTitle>
          <H3>Properties</H3>
          <span>
            Information received about this user that can be used for segmenting
            and targeting.{' '}
            <ExternalLink href="https://docs.appcues.com/article/769-user-profile#properties">
              Learn more
            </ExternalLink>
          </span>
        </PanelTitle>
        <PanelActions>
          <SearchInput
            placeholder="Search for property name"
            value={query}
            onChange={({ target: { value } }) => {
              setQuery(value);
            }}
          />
          <Filter
            filters={filters}
            onClear={handleFilterClear}
            onChange={handleFilterChange}
          />
        </PanelActions>
      </PanelHeader>
      <TableWrapper>
        <PropertiesTable>
          <Thead>
            <Tr>
              <Th>Starred</Th>
              <Th
                sortable
                sorted={
                  (sortConfig.column === 'label' && sortConfig.order) || null
                }
                onClick={() => handleColumnClick('label')}
              >
                Name
              </Th>
              <Th
                sortable
                sorted={
                  (sortConfig.column === 'value' && sortConfig.order) || null
                }
                onClick={() => handleColumnClick('value')}
              >
                Value
              </Th>
            </Tr>
          </Thead>
          {filteredProperties?.length > 0 && (
            <ScrollableTbody $rows={filteredProperties.length}>
              <AutoSizer>
                {({ width, height }) => (
                  <List
                    height={height}
                    width={width}
                    itemCount={filteredProperties.length}
                    itemSize={35}
                    itemData={filteredProperties}
                  >
                    {({ index, style }) => {
                      const currentProperty = filteredProperties[index];
                      const value = stringifyValue(currentProperty.value);
                      const profileAttribute = profileAttributes.find(
                        pa => pa.name === currentProperty.name
                      );

                      return (
                        <PropertiesRow
                          as="div"
                          role="row"
                          style={style}
                          key={currentProperty.name}
                        >
                          <Td as="div" role="cell">
                            <StarCheckbox
                              aria-label={`favorite ${currentProperty.label}`}
                              checked={currentProperty.isStarred}
                              onChange={isChecked => {
                                onStarredChange({
                                  name: currentProperty.name,
                                  isStarred: isChecked,
                                });
                              }}
                            />
                          </Td>
                          <Td
                            as="div"
                            role="cell"
                            title={currentProperty.label}
                          >
                            {currentProperty.label}
                          </Td>
                          <Td as="div" role="cell" title={value}>
                            <PropertyValue
                              value={value}
                              property={currentProperty}
                              profileAttribute={profileAttribute}
                            />
                          </Td>
                        </PropertiesRow>
                      );
                    }}
                  </List>
                )}
              </AutoSizer>
            </ScrollableTbody>
          )}
        </PropertiesTable>
        {isLoading && (
          <LoaderWrapper>
            <Spinner />
          </LoaderWrapper>
        )}
        {hasNoResults && (
          <NoResultsMessage>
            <img src={NoResults} alt="No data found" />
            <NoResultsMessageEmphasis>
              No results found
            </NoResultsMessageEmphasis>
            Try adjusting your filters for results
          </NoResultsMessage>
        )}
        {hasNoData && (
          <NoResultsMessage>
            <img src={NoData} alt="No data yet" />
            We don’t have data for you yet
          </NoResultsMessage>
        )}
      </TableWrapper>
    </Panel>
  );
}

Properties.propTypes = {
  onLoad: PropTypes.func,
  onStarredChange: PropTypes.func,
  userProperties: PropTypes.arrayOf(
    PropTypes.shape({
      isStarred: PropTypes.bool,
      label: PropTypes.string,
      name: PropTypes.string,
      type: PropTypes.string,
    })
  ),
};

const mapStateToProps = (state, { userId }) => {
  const profileAttributes = selectProfileAttributes(state) || [];
  return {
    userProperties: selectUserProfileAttributes(state, userId),
    profileAttributes,
  };
};

const mapDispatchToProps = (dispatch, { userId }) => ({
  onLoad: () => {
    dispatch(fetchUserProfile(userId));
    dispatch(fetchUserProfileAttributes());
  },
  onStarredChange: ({ name, isStarred }) =>
    dispatch(upsert({ name, isStarred })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Properties);
