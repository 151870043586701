import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import styled from 'styled-components';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';
import {
  H3,
  Tr,
  Th,
  Td,
  SearchInput,
  Thead,
  Panel,
  PanelHeader,
  PanelTitle,
  PanelActions,
  Spinner,
  Link,
  ExternalLink,
  startOf,
  subtract,
} from '@studio/legacy-components';
import { Filter } from 'next/components/Filter';
import useFilter from 'next/hooks/use-filter';
import useTitle from 'next/hooks/use-title';
import NoData from 'assets/images/no-data.svg';
import { getAnalytics, remove } from 'actions/analytics-v2';
import { selectUserActivityDetails } from 'selectors/user-activities';
import { getCacheKey } from 'utils/caching';

import {
  LoaderWrapper,
  NoResultsMessage,
  NoResultsMessageEmphasis,
  TableWrapper,
} from 'components/Audience/styled';
import { ScrollableTbody } from 'components/Audience/Profile/styled';
import NoResults from 'assets/images/no-results.svg';

import {
  ActivitiesTable,
  ActionIcon,
  Action,
  ActionLabel,
  ActivitiesRow,
} from './styled';

const ACTION_LABELS = {
  started: 'Started',
  completed: 'Completed',
  skipped: 'Skipped',
  triggered: 'Triggered',
};

const ACTION_ICONS = {
  started: 'adjust',
  completed: 'check-circle',
  skipped: 'ban',
  triggered: 'circle',
};

const ACTIVITY_LABELS = {
  flow: 'Flow',
  pin: 'Pin',
  banner: 'Banner',
  launchpad: 'Launchpad',
  checklist: 'Checklist',
  nps: 'NPS',
  goal: 'Goal',
  application: 'Application Event',
  builder: 'Track Events (Builder)',
};

const LAST_90_DAYS = new Date(
  subtract(startOf(Date.now(), 'day'), 90, 'days')
).getTime();

const ActivityTime = styled.span``;

export const Activities = ({ query, activities, onLoad, onUnload }) => {
  useTitle('Activities | Users | Appcues');
  const [searchTerm, setSearchTerm] = useState('');
  const { filters, filterFunction, handleFilterChange, handleFilterClear } =
    useFilter({
      actions: {
        name: 'Actions',
        options: Object.entries(ACTION_LABELS).reduce(
          (acc, [action, label]) => {
            acc[action] = {
              label: (
                <ActionLabel>
                  <Action>{label}</Action>
                  <ActionIcon $action={action} icon={ACTION_ICONS[action]} />
                </ActionLabel>
              ),
              enabled: false,
              filterFn: ({ action: a }) => a === action,
            };
            return acc;
          },
          {}
        ),
      },
      activities: {
        name: 'Activities',
        options: Object.entries(ACTIVITY_LABELS).reduce(
          (acc, [activity, label]) => {
            acc[activity] = {
              label,
              enabled: false,
              filterFn: ({ activity: a }) => a === activity,
            };
            return acc;
          },
          {}
        ),
      },
    });

  useEffect(() => {
    onLoad?.({ query });
    return () => {
      onUnload?.(getCacheKey(query));
    };
  }, []);

  const filteredActivities = activities
    ?.filter(({ displayName }) =>
      `${displayName}`.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .filter(filterFunction);

  const isLoading = !activities;
  const hasNoResults = activities?.length === 0;
  const hasNoMatches = !hasNoResults && filteredActivities?.length === 0;

  return (
    <Panel>
      <PanelHeader>
        <PanelTitle>
          <H3>Activities</H3>
          <span>
            Timeline of this user&lsquo;s activity within the last 90 days (max
            1,000 events){' '}
            <ExternalLink href="https://docs.appcues.com/article/769-user-profile#activities">
              Learn more
            </ExternalLink>
          </span>
        </PanelTitle>
        <PanelActions>
          <SearchInput
            placeholder="Search by name"
            value={searchTerm}
            onChange={({ target: { value } }) => {
              setSearchTerm(value);
            }}
          />
          <Filter
            filters={filters}
            onChange={handleFilterChange}
            onClear={handleFilterClear}
          />
        </PanelActions>
      </PanelHeader>
      <TableWrapper>
        <ActivitiesTable sticky>
          <Thead>
            <Tr>
              <Th>Action</Th>
              <Th>Activity</Th>
              <Th>Name</Th>
              <Th>Time</Th>
            </Tr>
          </Thead>
          {filteredActivities?.length > 0 && (
            <ScrollableTbody $rows={filteredActivities.length}>
              <AutoSizer>
                {({ width, height }) => (
                  <List
                    height={height}
                    width={width}
                    itemCount={filteredActivities.length}
                    itemSize={35}
                    itemData={filteredActivities}
                  >
                    {({ index, style }) => {
                      const { action, activity, displayName, href, timestamp } =
                        filteredActivities[index];

                      const lastUpdated =
                        moment().diff(timestamp, 'months', true) > 1
                          ? moment(timestamp).format('lll')
                          : moment(timestamp).fromNow();

                      return (
                        <ActivitiesRow style={style} as="div" role="row">
                          <Td as="div" role="cell">
                            <ActionLabel>
                              <ActionIcon
                                $action={action}
                                icon={ACTION_ICONS[action]}
                              />
                              <Action>{ACTION_LABELS[action]}</Action>
                            </ActionLabel>
                          </Td>
                          <Td as="div" role="cell">
                            {ACTIVITY_LABELS[activity]}
                          </Td>
                          <Td as="div" role="cell">
                            <Link
                              title={displayName}
                              to={encodeURI(href)}
                              target="_blank"
                            >
                              {displayName}
                            </Link>
                          </Td>
                          <Td as="div" role="cell">
                            <ActivityTime
                              title={moment(timestamp).format('lll')}
                            >
                              {lastUpdated}
                            </ActivityTime>
                          </Td>
                        </ActivitiesRow>
                      );
                    }}
                  </List>
                )}
              </AutoSizer>
            </ScrollableTbody>
          )}
        </ActivitiesTable>
        {isLoading && (
          <LoaderWrapper>
            <Spinner aria-label="Loading activities" />
            This data is on its way, but may take up to a minute to load.
          </LoaderWrapper>
        )}
        {hasNoResults && (
          <NoResultsMessage>
            <img src={NoData} alt="No data found" />
            We don’t have data for you yet
          </NoResultsMessage>
        )}
        {hasNoMatches && (
          <NoResultsMessage>
            <img src={NoResults} alt="No data found" />
            <NoResultsMessageEmphasis>
              No results found
            </NoResultsMessageEmphasis>
            Try adjusting your filters for results
          </NoResultsMessage>
        )}
      </TableWrapper>
    </Panel>
  );
};

Activities.propTypes = {
  activities: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.oneOf(Object.keys(ACTION_ICONS)),
      activity: PropTypes.oneOf(Object.values(ACTIVITY_LABELS)),
      displayName: PropTypes.string,
      href: PropTypes.string,
      timestamp: PropTypes.string,
    })
  ),
  query: PropTypes.shape({
    columns: PropTypes.arrayOf(PropTypes.string),
    dimensions: PropTypes.arrayOf(PropTypes.string),
    conditions: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
      ])
    ),
    order_by: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
    limit: PropTypes.number,
  }),
  pageSize: PropTypes.number,
  onLoad: PropTypes.func,
  onUnload: PropTypes.func,
};

const mapStateToProps = (state, { userId, endTime: end_time }) => {
  const query = {
    columns: [
      'id',
      'timestamp',
      'name',
      'flow_id',
      'checklist_id',
      'experience_id',
      'experience_type',
      'step_id',
      'step_type',
      'interaction_data',
      'search_query',
      'goal_id',
      'a:score',
      'a:feedback',
    ],
    dimension: ['user_id'],
    conditions: [
      ['user_id', '==', userId],
      [
        'or',
        [
          'name',
          'in',
          [
            'appcues:flow_started',
            'appcues:flow_completed',
            'appcues:checklist_shown',
            'appcues:checklist_completed',
            'appcues:checklist_skipped',
            'appcues:nps_score',
            'appcues:nps_feedback',
            'appcues:goal_reached',
            'appcues:v2:experience_started',
            'appcues:v2:experience_completed',
            'appcues:v2:experience_dismissed',
            'appcues:v2:step_interaction',
            'appcues:v2:step_seen',
            'appcues:v2:launchpad_kb_search',
          ],
        ],
        ['event_type', 'in', ['ctt', 'other']],
      ],
    ],
    order_by: [['timestamp', 'desc']],
    start_time: LAST_90_DAYS,
    end_time,
    limit: 1000,
    meta: {
      tags: {
        feature: 'User Profile Activities',
      },
    },
  };

  return {
    query,
    activities: selectUserActivityDetails(state, query),
  };
};

const mapDispatchToProps = {
  onLoad: getAnalytics,
  onUnload: remove,
};

const ConnectedActivities = connect(
  mapStateToProps,
  mapDispatchToProps
)(Activities);

ConnectedActivities.propTypes = {
  userId: PropTypes.string,
  endTime: PropTypes.number,
};

export default ConnectedActivities;
