import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  H3,
  RadioGroup,
  Radio,
  RadioControl,
  RadioLabel,
  PanelHeader,
  Panel,
  PanelTitle,
  PanelSection,
  PanelSectionTitle,
  PanelBody,
  Tooltip,
  ExternalLink,
  HorizontalScroll,
  Select,
  Button,
} from '@studio/legacy-components';
import { getLeafNodes, ConditionsShape } from '@studio/conditions';
import { faLock } from '@fortawesome/pro-regular-svg-icons/faLock';
import { Conditions } from 'next/components/Conditions';
import { BeaconLink } from 'next/components/BeaconLink';
import { SelectOptionsShape } from 'next/lib/shapes';
import { selectEventTargetingOptions } from 'next/lib/selectors-options';
import {
  ENTITLEMENTS,
  selectIsEntitledByName,
} from 'next/entities/entitlements';

import {
  TargetingEvent,
  UpgradeBadge,
  UpgradeMessage,
  ConditionsLabel,
  TargetingConditions,
} from './styled';

/**
 * Analyzes "trigger branches" provided by the parent to determine
 * which mode is currently active
 *
 * @param {Array} triggerBranch
 * @param {String} frequency
 * @returns {String} session, event, screen, never
 */

const getCurrentTrigger = (triggerBranch, frequency) => {
  if (triggerBranch) {
    // if there is a trigger branch, this must be event triggered.
    // determine if that event is session_started or custom
    const triggerNodes = getLeafNodes(triggerBranch);
    const hasSessionTrigger = triggerNodes.some(
      ({ trigger: { event } }) => event === 'appcues:session_started'
    );

    return hasSessionTrigger ? 'session' : 'event';
  }

  if (frequency === 'never') {
    return 'never';
  }

  // if there is no trigger branch, the api will fall back to screen
  return 'screen';
};

const EVENT_TRIGGER_UPGRADE_LABEL = (
  <UpgradeMessage>
    <BeaconLink
      aria-label="Upgrade to trigger Flows when a specific event occurs"
      subject="Interested in event triggering for Flows"
      message="I'd like to enable event triggering for Flows."
    >
      Contact support
    </BeaconLink>{' '}
    to upgrade and trigger Flows when a specific event occurs. <br />
    <ExternalLink href="https://docs.appcues.com/en_US/user-experiences-studio/event-triggering">
      Learn more
    </ExternalLink>
  </UpgradeMessage>
);

const STICKY_FREQUENCY_VALUES = new Set(['once', 'every_time']);

export const BASE_ATTRIBUTE_CONDITIONS = {
  or: [
    {
      attributes: { attribute: '', operator: '*', value: '' },
    },
  ],
};

export const Trigger = ({
  conditions,
  frequency,
  events = [],
  hasEventTriggering,
  onChange,
}) => {
  const currentTrigger = getCurrentTrigger(conditions, frequency);

  const [prevFrequency, setPrevFrequency] = useState(
    STICKY_FREQUENCY_VALUES.has(frequency) ? frequency : 'once'
  );

  useEffect(() => {
    if (STICKY_FREQUENCY_VALUES.has(frequency)) {
      setPrevFrequency(frequency);
    }
  }, [frequency]);

  const [triggerEvent, triggerEventConditions] = useMemo(() => {
    if (!conditions) {
      return [null, null];
    }

    const [
      {
        trigger: { event, conditions: triggerConditions = null },
      },
    ] = conditions.and || conditions.or;

    return [event, triggerConditions];
  }, [conditions]);

  const eventOptions = useMemo(() => {
    if (!triggerEvent) {
      return events;
    }

    const opts = [...events];

    if (triggerEvent && !opts.some(({ value }) => value === triggerEvent)) {
      opts.push({
        label: triggerEvent,
        value: triggerEvent,
      });
    }

    return opts;
  }, [triggerEvent, events]);

  const handleTriggerChange = nextTrigger => {
    switch (nextTrigger) {
      case 'session':
        onChange(
          { or: [{ trigger: { event: 'appcues:session_started' } }] },
          {
            frequency: prevFrequency,
          }
        );
        break;
      case 'screen':
        onChange(null, {
          frequency: prevFrequency,
        });
        break;
      case 'event':
        onChange(
          { or: [{ trigger: { event: '' } }] },
          {
            frequency: prevFrequency,
          }
        );
        break;
      case 'never':
        onChange(null, { frequency: 'never' });
        break;
      default:
        break;
    }
  };

  const handleFrequencyChange = nextFrequency =>
    void onChange(conditions, { frequency: nextFrequency });

  const handleTriggerEventChange = ({
    event = triggerEvent,
    conditions: triggerConditions = triggerEventConditions,
  }) =>
    void onChange(
      {
        or: [
          {
            trigger: {
              event,
              conditions: triggerConditions,
            },
          },
        ],
      },
      {}
    );

  const handleToggleTriggerEventConditions = () => {
    handleTriggerEventChange({
      conditions: triggerEventConditions ? null : BASE_ATTRIBUTE_CONDITIONS,
    });
  };

  const enableEventTrigger = currentTrigger === 'event' || hasEventTriggering;

  const triggerEventAttributes = getLeafNodes(triggerEventConditions) || [];

  return (
    <Panel>
      <PanelHeader>
        <PanelTitle>
          <H3>Trigger</H3>
        </PanelTitle>
      </PanelHeader>
      <PanelBody>
        <PanelSection>
          <PanelSectionTitle>When should this Flow launch?</PanelSectionTitle>
          <RadioGroup
            name="trigger"
            value={currentTrigger}
            onValueChange={handleTriggerChange}
          >
            <RadioControl>
              <Radio id="session" value="session" />
              <RadioLabel htmlFor="session">
                When users start an app session
              </RadioLabel>
            </RadioControl>

            <RadioControl>
              <Radio id="screen" value="screen" />
              <RadioLabel htmlFor="screen">
                When users reach a screen
              </RadioLabel>
            </RadioControl>

            {enableEventTrigger && (
              <RadioControl>
                <Radio id="event" value="event" />
                <RadioLabel htmlFor="event">When an event occurs</RadioLabel>
              </RadioControl>
            )}

            {currentTrigger === 'event' && hasEventTriggering && (
              <>
                <TargetingEvent>
                  <Select
                    value={eventOptions.find(
                      ({ value }) => value === triggerEvent
                    )}
                    options={eventOptions}
                    onChange={({ value }) =>
                      handleTriggerEventChange({ event: value })
                    }
                  />
                  {Boolean(triggerEvent) && (
                    <Button
                      kind="secondary"
                      onClick={handleToggleTriggerEventConditions}
                    >
                      {triggerEventConditions
                        ? 'Remove attributes'
                        : 'Add attributes'}
                    </Button>
                  )}
                </TargetingEvent>

                {Boolean(triggerEvent) && triggerEventConditions && (
                  <TargetingConditions>
                    {triggerEventAttributes.length === 1 && (
                      <ConditionsLabel>
                        Only launch if event attributes match:
                      </ConditionsLabel>
                    )}
                    <HorizontalScroll>
                      <Conditions
                        validClauseTypes={['attributes']}
                        conditions={triggerEventConditions}
                        onChange={newConditions =>
                          handleTriggerEventChange({
                            conditions: newConditions,
                          })
                        }
                      />
                    </HorizontalScroll>
                  </TargetingConditions>
                )}
              </>
            )}

            <RadioControl>
              <Radio id="never" value="never" />
              <RadioLabel htmlFor="never">Only manually</RadioLabel>
            </RadioControl>

            {!enableEventTrigger && (
              <RadioControl disabled>
                <Radio id="event" value="event" disabled />
                <RadioLabel htmlFor="event" disabled>
                  When an event occurs
                  <Tooltip wrapped persist label={EVENT_TRIGGER_UPGRADE_LABEL}>
                    <UpgradeBadge icon={faLock}>Upgrade</UpgradeBadge>
                  </Tooltip>
                </RadioLabel>
              </RadioControl>
            )}
          </RadioGroup>
        </PanelSection>

        {currentTrigger !== 'never' && (
          <PanelSection>
            <PanelSectionTitle>
              How often should we show this flow?
            </PanelSectionTitle>
            <RadioGroup
              name="frequency"
              value={frequency}
              onValueChange={handleFrequencyChange}
            >
              <RadioControl>
                <Radio id="once" value="once" />
                <RadioLabel htmlFor="once">Show once</RadioLabel>
              </RadioControl>
              <RadioControl>
                <Radio id="every_time" value="every_time" />
                <RadioLabel htmlFor="every_time">Show every time</RadioLabel>
              </RadioControl>
            </RadioGroup>
          </PanelSection>
        )}
      </PanelBody>
    </Panel>
  );
};

Trigger.propTypes = {
  conditions: ConditionsShape,
  frequency: PropTypes.oneOf(['once', 'every_time', 'never']),
  events: SelectOptionsShape,
  hasEventTriggering: PropTypes.bool,
  onChange: PropTypes.func,
};

const mapStateToProps = state => ({
  events: selectEventTargetingOptions(state),
  hasEventTriggering: selectIsEntitledByName(
    state,
    ENTITLEMENTS.EVENT_TRIGGERING_ACCESS
  ),
});

export default connect(mapStateToProps)(Trigger);
