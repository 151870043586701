import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { connect } from 'react-redux';
import {
  ExpandableText,
  Table,
  Thead,
  Th,
  Tbody,
  Tr,
  Td,
  Select,
  Link,
} from '@studio/legacy-components';
import SelectProfileAttribute from 'components/Common/SelectProfileAttribute';
import UserProfileModalManager from 'components/Insights/Charts/View/EventsByUserTable/UserProfileModalManager';
import { selectAccountFeature } from 'reducers/account/features';
import { USER_PROFILE } from 'constants/features';
import {
  selectUserProfileAttributes,
  callApi as fetchUserProfileAttributes,
} from 'entities/user-profiles-attributes';
import Property from 'components/Common/Property';
import { asPromised } from 'utils/as-promised';
import ScoreIcon from './ScoreIcon';

const TableWrapper = styled.div`
  border-top: 1px solid var(--table-border);
  margin: 12px 0px 20px;
  min-height: 450px;
  min-width: 870px;
  overflow-x: auto;

  @media print {
    min-width: 0;
  }
`;

const PropertyHeader = styled(Th)`
  white-space: normal;
  width: 220px;

  ${Select} {
    min-width: 200px;
    max-width: 200px;
  }

  @media print {
    width: auto;

    ${Select} {
      min-width: 100px;
      max-width: none;
    }
  }
`;

const PropertyHeaderOne = styled(PropertyHeader)`
  padding-right: 12px;
`;

const PropertyHeaderTwo = styled(PropertyHeader)`
  padding-left: 12px;
`;

const ScoreHeader = styled(Th)`
  width: 100px;

  @media print {
    width: auto;
  }
`;

const DateHeader = styled(Th)`
  width: 204px;

  @media print {
    width: auto;
  }
`;

const PropertyColumn = styled(Td)`
  white-space: normal;
  word-break: break-all;
`;

const FeedbackColumn = styled(Td)`
  white-space: normal;
`;

const NoFeedback = styled.span`
  color: var(--text-secondary);
  font-style: italic;
`;

export const ResponsesTable = ({
  responses = [],
  orderByColumn,
  orderByDirection,
  onSort,
  hasUserProfile,
  onLoad,
  userProfileAttributes = [],
}) => {
  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const [userProperty1, setUserProperty1] = useState('email');
  const profileAttribute1 = userProfileAttributes.find(
    attribute => attribute.name === userProperty1
  );
  const [userProperty2, setUserProperty2] = useState('role');
  const profileAttribute2 = userProfileAttributes.find(
    attribute => attribute.name === userProperty2
  );

  const handleSort = accessor => () => {
    onSort(accessor);
  };

  return (
    <TableWrapper>
      <Table>
        <Thead>
          <Tr>
            <PropertyHeaderOne>
              <SelectProfileAttribute
                value={userProperty1}
                onChange={field => {
                  setUserProperty1(field.value);
                }}
                placeholder="Select user property"
              />
            </PropertyHeaderOne>
            <PropertyHeaderTwo>
              <SelectProfileAttribute
                value={userProperty2}
                onChange={field => {
                  setUserProperty2(field.value);
                }}
                placeholder="Select user property"
              />
            </PropertyHeaderTwo>
            <ScoreHeader
              sortable
              sorted={
                (orderByColumn === 'nps_raw_score' && orderByDirection) || null
              }
              onClick={handleSort('nps_raw_score')}
            >
              Score
            </ScoreHeader>
            <Th
              sortable
              sorted={
                (orderByColumn === 'nps_feedback' && orderByDirection) || null
              }
              onClick={handleSort('nps_feedback')}
            >
              Feedback
            </Th>
            <DateHeader
              sortable
              sorted={
                (orderByColumn === 'timestamp' && orderByDirection) || null
              }
              onClick={handleSort('timestamp')}
            >
              Date received
            </DateHeader>
          </Tr>
        </Thead>
        <Tbody>
          {responses.map(
            ({
              user_id,
              nps_feedback,
              nps_raw_score,
              timestamp,
              'a:_identity': profile,
            }) => {
              return (
                <Tr key={timestamp}>
                  <PropertyColumn>
                    {hasUserProfile ? (
                      <Link to={`/users/${encodeURIComponent(user_id)}`}>
                        {profileAttribute1 && profile?.[userProperty1] ? (
                          <Property
                            type={profileAttribute1.type}
                            value={profile?.[userProperty1] || 'N/A'}
                          />
                        ) : (
                          profile?.[userProperty1] || 'N/A'
                        )}
                      </Link>
                    ) : (
                      <UserProfileModalManager
                        userId={user_id}
                        identity={profile}
                        timestamp={timestamp}
                      >
                        {profileAttribute1 && profile?.[userProperty1] ? (
                          <Property
                            type={profileAttribute1.type}
                            value={profile?.[userProperty1] || 'N/A'}
                          />
                        ) : (
                          profile?.[userProperty1] || 'N/A'
                        )}
                      </UserProfileModalManager>
                    )}
                  </PropertyColumn>
                  <PropertyColumn>
                    {profileAttribute2 && profile?.[userProperty2] ? (
                      <Property
                        type={profileAttribute2.type}
                        value={profile?.[userProperty2] || 'N/A'}
                      />
                    ) : (
                      profile?.[userProperty2] || 'N/A'
                    )}
                  </PropertyColumn>
                  <Td>
                    {nps_raw_score != null && (
                      <ScoreIcon score={nps_raw_score} />
                    )}
                  </Td>
                  <FeedbackColumn>
                    {nps_feedback ? (
                      <ExpandableText lines={3}>{nps_feedback}</ExpandableText>
                    ) : (
                      <NoFeedback>No Feedback</NoFeedback>
                    )}
                  </FeedbackColumn>
                  <Td>{moment(timestamp).format('MMM D, YYYY h:mm A')}</Td>
                </Tr>
              );
            }
          )}
        </Tbody>
      </Table>
    </TableWrapper>
  );
};

ResponsesTable.propTypes = {
  responses: PropTypes.arrayOf(
    PropTypes.shape({
      user_id: PropTypes.string,
      nps_feedback: PropTypes.string,
      nps_raw_score: PropTypes.number,
      timestamp: PropTypes.string,
      'a:_identity': PropTypes.shape({
        email: PropTypes.string,
      }),
    })
  ),
  orderByColumn: PropTypes.string,
  orderByDirection: PropTypes.string,
  onSort: PropTypes.func,
};

const mapStateToProps = state => ({
  hasUserProfile: selectAccountFeature(state, USER_PROFILE),
  userProfileAttributes: selectUserProfileAttributes(state) || [],
});

const mapDispatchToProps = dispatch => ({
  onLoad: () => asPromised(dispatch, fetchUserProfileAttributes()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResponsesTable);
