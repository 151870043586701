import styled from 'styled-components';
import {
  Button,
  Checkbox,
  ExternalLink,
  Icon,
  Input,
  Label,
  Link,
  PanelSectionTitle,
  Select,
  Td,
} from '@studio/legacy-components';
import { Badge } from '@appcues/sonar';
import { BeaconLink } from 'next/components/BeaconLink';

export const CheckboxLabel = styled.span`
  font-weight: var(--normal);
`;

export const ConditionsLabel = styled.span`
  color: var(--pleather);
`;

export const DetailsForm = styled.form`
  ${Label} {
    padding-bottom: 24px;
  }
`;

export const GoalInfo = styled.p`
  margin-bottom: 0px;
  ${ExternalLink} {
    svg {
      margin-right: 2px;
    }
  }
`;

export const GoalInfoWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

export const GoalSelectWrapper = styled.div`
  padding-left: 24px;
  padding-bottom: 6px;
`;

export const HalfSizeInput = styled(Input)`
  width: 50%;
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--white-walker);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  color: var(--blues-cues);
  flex-shrink: 0;
`;

export const Loading = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
`;

export const NewGoal = styled.div`
  margin-top: -6px;
  padding-bottom: 8px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${PanelSectionTitle} {
    font-weight: var(--normal);
    flex: 1;
  }
`;

export const SectionCheckbox = styled(Checkbox)`
  margin-bottom: 8px;
`;

export const Targeting = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 16px 0 0 24px;

  > ${Select} {
    margin-bottom: 16px;
  }
`;

export const TargetingConditions = styled(Targeting)`
  margin-top: 0px;
  gap: 0px;

  ${ConditionsLabel} {
    display: block;
    margin-bottom: 8px;
  }
`;

export const TargetingEvent = styled(Targeting)`
  flex-direction: row;
  align-items: center;

  ${Button} {
    margin-bottom: 16px;
  }
`;

export const UpgradeBadge = styled(Badge)`
  margin-left: 4px;
`;

export const UpgradeMessage = styled.span`
  ${Link}, ${ExternalLink} {
    color: var(--white);

    > ${Icon} {
      color: var(--white);
    }
  }
  ${BeaconLink} {
    text-decoration: none;
  }
`;

export const TableContainer = styled.div`
  ${Td} {
    border: none;
    vertical-align: top;

    &:nth-of-type(1) {
      font-weight: var(--bold);
      width: 100px;
    }

    &:nth-of-type(2) {
      max-width: 30em;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;

      &::first-letter {
        text-transform: uppercase;
      }
    }
  }
`;
