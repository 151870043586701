import React from 'react';
import PropTypes from 'prop-types';

import { Link } from '@studio/legacy-components';
import { Text, Tooltip } from '@appcues/sonar';
import Property from 'components/Common/Property';

export function PropertyValue({ value, property, profileAttribute }) {
  if (property.name === '_lastGroupId') {
    return (
      <Tooltip
        content={
          <Text size="small" lineHeight="large" colorToken="foreground-tooltip">
            Open Account Profile
          </Text>
        }
      >
        <Link to={`/audience/accounts/${property.value}/overview`}>
          {profileAttribute ? (
            <Property type={profileAttribute.type} value={value} />
          ) : (
            value
          )}
        </Link>
      </Tooltip>
    );
  }
  return profileAttribute ? (
    <Property type={profileAttribute.type} value={value} />
  ) : (
    value
  );
}

PropertyValue.propTypes = {
  value: PropTypes.string.isRequired,
  property: PropTypes.object.isRequired,
  profileAttribute: PropTypes.object,
};
