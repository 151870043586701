import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Column } from '@appcues/component-library';
import { NEXT_SUBSCRIPTION } from 'next/entities/features';
import { DocumentTitle } from 'next/hooks/use-title';
import { findSubscription } from 'actions/newBilling';
import { navigate } from 'actions/routing';
import Loader from 'components/Common/Loader';
import BillingDetails from 'components/Subscription/BillingDetails';
import BillingHistory from 'components/Subscription/BillingHistory';
import CanceledNotice from 'components/Subscription/CanceledNotice';
import { CurrentPeriod } from 'components/Subscription/CurrentPeriod';
import FreeTrial from 'components/Subscription/FreeTrial';
import InvoiceMessage from 'components/Subscription/InvoiceMessage';
import { YourPlan } from 'components/Subscription/YourPlan';
import ManageSubscription from 'components/Subscription/ManageSubscription';
import { selectAccountFeature } from 'reducers/account/features';
import {
  selectAccountMeta,
  selectAccountMetaSynced,
} from 'reducers/account/meta';
import { selectUser, selectUserSynced } from 'reducers/user';
import {
  selectAccountStats,
  selectAccountStatsSynced,
} from 'reducers/account/stats';
import { selectBilling } from 'reducers/billing';
import { selectAccountEntitlementsProcessed } from 'reducers/entitlements';
import { selectMausProcessed } from 'selectors/entitlements';
import { selectCurrentPlanData } from 'selectors/billing';
import { asPromised } from 'utils/as-promised';

import CurrentEntitledPeriod from './CurrentPeriod/CurrentEntitledPeriod';
import { StyledNotice, PlanUsage } from './styled';

export function Subscription({
  accountMeta,
  allTimeStats,
  billing,
  entitlementsProcessed,
  isSynced,
  planData,
  mausProcessed,
  subscription,
  user,
  isStripeCustomer,
  subscriptionId: id,
  navigateTo,
  onLoadSubscription,
  hasNextSubscription,
}) {
  const [showSubscriptionBanner, setShowSubscriptionBanner] = useState(false);
  const [subscriptionLoaded, setSubscriptionLoaded] = useState(false);
  const {
    canceledAt,
    isTrial,
    stripeSubEndedAt: accountCanceledAt,
  } = accountMeta;

  // useEffect is not supported by Enzyme's shallow rendering at version we are using. This is a workaround.
  React.useEffect(() => {
    const loadSubscriptionConfig = {};

    if (id) {
      loadSubscriptionConfig.id = id;
      setShowSubscriptionBanner(true);
    } else if (accountMeta.stripeId) {
      loadSubscriptionConfig.stripeId = accountMeta.stripeId;
    }

    onLoadSubscription(loadSubscriptionConfig).then(() => {
      setSubscriptionLoaded(true);
    });
  }, []);

  const navigateToStory = () => {
    navigateTo('/subscription/story');
  };

  if (isTrial && !canceledAt) {
    return hasNextSubscription ? (
      navigateTo('/settings/subscription-v2')
    ) : (
      <FreeTrial />
    );
  }

  if (!isSynced || !subscriptionLoaded) {
    return <Loader />;
  }

  return (
    <Column>
      <DocumentTitle title="Subscription | Settings | Appcues" />
      {showSubscriptionBanner && !canceledAt && (
        <StyledNotice
          type="success"
          onDismiss={() => setShowSubscriptionBanner(false)}
        >
          🎉 <strong>Woo hoo!</strong> You’ve successfully upgraded. A
          confirmation email was sent to <strong>{user.email}</strong>
        </StyledNotice>
      )}
      {canceledAt && (
        <CanceledNotice
          canceledAt={canceledAt}
          billingDetails={billing.details}
        />
      )}
      {planData && Object.keys(planData).length > 0 && (
        <PlanUsage>
          <YourPlan planData={planData} mausProcessed={mausProcessed} />

          {['start', 'grow', 'scale', 'enterprise-v2'].includes(
            accountMeta?.planId
          ) ? (
            <CurrentEntitledPeriod />
          ) : (
            <CurrentPeriod
              accountId={accountMeta.id}
              anonymousWebsiteMaus={
                allTimeStats.monthly_anonymous_website_users
              }
              anonymousMobileMaus={allTimeStats.monthly_mobile_anonymous_users}
              entitlementsProcessed={entitlementsProcessed}
              mausProcessed={mausProcessed}
              monthlyActiveUsers={allTimeStats.monthly_website_users}
              uuid={accountMeta.uuid}
            />
          )}
        </PlanUsage>
      )}
      <>
        {isStripeCustomer && billing.details && (
          <BillingDetails billingDetails={billing.details} />
        )}

        {isStripeCustomer && billing.history && (
          <BillingHistory billingHistory={billing.history} />
        )}

        {!isStripeCustomer && <InvoiceMessage />}
      </>
      {subscription && !canceledAt && (
        <ManageSubscription
          accountCanceledAt={accountCanceledAt}
          billingDetails={billing.details}
          navigateToStory={navigateToStory}
        />
      )}
    </Column>
  );
}

const mapStateToProps = (state, routeProps) => {
  const query = new URLSearchParams(routeProps.location.search);
  const id = query.get('id');
  const userSynced = selectUserSynced(state);
  const accountMetaSynced = selectAccountMetaSynced(state);
  const accountStatsSynced = selectAccountStatsSynced(state);
  const isSynced = userSynced && accountMetaSynced && accountStatsSynced;

  const accountMeta = selectAccountMeta(state);
  const isStripeCustomer = accountMeta.stripeId?.startsWith('cus_');

  return {
    accountMeta,
    allTimeStats: selectAccountStats(state),
    billing: selectBilling(state),
    entitlementsProcessed: selectAccountEntitlementsProcessed(state),
    isSynced,
    planData: selectCurrentPlanData(state),
    mausProcessed: selectMausProcessed(state),
    subscription: state.newBilling.subscription,
    user: selectUser(state),
    isStripeCustomer,
    subscriptionId: id,
    hasNextSubscription: selectAccountFeature(state, NEXT_SUBSCRIPTION),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    navigateTo: path => dispatch(navigate(path)),
    onLoadSubscription: payload =>
      asPromised(dispatch, findSubscription(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Subscription);
