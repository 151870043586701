import React from 'react';
import { Icon, Row, Text, Tooltip } from '@appcues/sonar';
import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons/faTriangleExclamation';
import { formatProperty } from 'utils/format-property';
import { PropertyLink } from './styled';

export default function Property({ type, value }) {
  const { rawValue, formattedValue, hasError } = formatProperty(value, type);

  return (
    <Row yAlign="center" spacing="small">
      <span>{hasError ? rawValue : formattedValue}</span>
      {hasError && (
        <Tooltip
          isOpen
          content={
            <Text
              size="small"
              lineHeight="large"
              colorToken="foreground-tooltip"
            >
              Unable to format this value into a {type.toLocaleLowerCase()}{' '}
              format. Adjust the{' '}
              <PropertyLink size="small" to="/settings/properties-v2">
                property settings
              </PropertyLink>{' '}
              or send a new value.
            </Text>
          }
          size="large"
        >
          <Icon
            icon={faTriangleExclamation}
            colorToken="foreground-warning"
            aria-hidden="false"
            aria-label="Triangle Exclamation"
          />
        </Tooltip>
      )}
    </Row>
  );
}
