import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { ENTITLEMENTS } from 'next/entities/entitlements';
import { selectIsEntitledByName } from 'selectors/entitlements-v2';
import LocalizationPrompt from 'components/StepSettings/Localization/LocalizationPrompt';
import LocalizationPanel from 'components/StepSettings/Localization/LocalizationPanel';

import { selectAccountFeatures } from 'reducers/account/features';
import { selectFlow } from 'reducers/account/flows';
import { selectLocales } from 'reducers/account/locales';
import { selectTranslations } from 'reducers/account/translations';
import { selectUser } from 'reducers/user';
import {
  fetchTranslations,
  updateFlowNameTranslation,
} from 'actions/account/translations';
import {
  LOCALIZATION_RELEASED,
  LAUNCHPAD_FLOW_NAME_LOCALIZATION,
} from 'constants/features';

import FeatureGate from 'components/Common/FeatureGate/FeatureGate';

import EmptyState from './EmptyState';

export const Localization = ({
  flow,
  onLoad,
  locales,
  translations,
  updateNameTranslation,
  hasLaunchpadFlowNameLocalization,
  hasLocalizationAccess,
  email,
  fullName,
}) => {
  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const handleLocalizeNameFlyoutSubmit = values => {
    const payload = {
      id: flow.id,
      translations: Object.keys(values).map(key => ({
        translationId: key,
        name: values[key],
      })),
    };
    updateNameTranslation(payload);
  };

  // If locales exist, just short circuit and render the table without all the logic checking
  if (Object.keys(locales).length > 0) {
    return (
      <LocalizationPanel
        flow={flow}
        locales={locales}
        onLocalizeDisplayName={handleLocalizeNameFlyoutSubmit}
        translations={translations}
        hasLaunchpadFlowNameLocalization={hasLaunchpadFlowNameLocalization}
        email={email}
        fullName={fullName}
      />
    );
  }

  return hasLocalizationAccess ? (
    <EmptyState />
  ) : (
    <FeatureGate feature={LOCALIZATION_RELEASED}>
      {released => released && <LocalizationPrompt />}
    </FeatureGate>
  );
};

Localization.propTypes = {
  flow: PropTypes.object,
  onLoad: PropTypes.func,
  locales: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      conditions: PropTypes.object,
    })
  ),
  hasLaunchpadFlowNameLocalization: PropTypes.bool,
  hasLocalizationAccess: PropTypes.bool,
  email: PropTypes.string,
  fullName: PropTypes.string,
};

Localization.defaultProps = {
  locales: {},
};

const mapStateToProps = (state, { flowId }) => {
  const features = selectAccountFeatures(state);
  const { email, fullName } = selectUser(state);

  return {
    email,
    fullName,
    flow: selectFlow(state, flowId),
    locales: selectLocales(state),
    translations: selectTranslations(state) || {},
    hasLocalizationAccess: selectIsEntitledByName(
      state,
      ENTITLEMENTS.LOCALIZATION_ACCESS
    ),
    hasLaunchpadFlowNameLocalization:
      features[LAUNCHPAD_FLOW_NAME_LOCALIZATION],
  };
};

const mapDispatchToProps = (dispatch, { flowId }) => ({
  onLoad: () => dispatch(fetchTranslations({ flowId })),
  updateNameTranslation: data => dispatch(updateFlowNameTranslation(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Localization);
