import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CSkeleton } from '@appcues/component-library';
import { Link } from '@studio/legacy-components';
import { USER_PROFILE_MODAL } from 'constants/globals';
import { USER_PROFILE } from 'constants/features';
import { fetchUserProfile } from 'actions/userProfiles';
import { selectAccountFeature } from 'reducers/account/features';
import { selectAccountUserProfile } from 'reducers/account/userProfiles';
import { showModal } from 'actions/currentModal';
import Property from 'components/Common/Property';

export class UserSampleTableUserCell extends Component {
  componentDidMount() {
    const { userId, onLoad } = this.props;
    onLoad(userId);
  }

  onClickUserId = () => {
    const { userProfile, onShowModal, userId } = this.props;

    onShowModal(USER_PROFILE_MODAL, {
      userProfile,
      userId,
    });
  };

  getUserIdentifier = () => {
    const { userProfile, profileAttributeColumn, userId } = this.props;

    const enhancedUserProfile = {
      ...userProfile,
      user_id: userId,
    };

    const userAttribute =
      enhancedUserProfile && enhancedUserProfile[profileAttributeColumn];
    if (userAttribute === null || typeof userAttribute === 'boolean') {
      return `${userAttribute}`;
    }

    if (typeof userAttribute === 'object') {
      return '-';
    }

    return userAttribute || '-';
  };

  render() {
    const {
      userProfile,
      className,
      hasUserProfile,
      userId,
      renderAsLink,
      profileAttribute,
    } = this.props;

    if (!userProfile) {
      return <CSkeleton height={16} />;
    }

    const userIdentifier = this.getUserIdentifier();
    const redirectionProp = hasUserProfile
      ? {
          to: `/users/${encodeURIComponent(userId)}`,
        }
      : {
          onClick: this.onClickUserId,
        };

    if (renderAsLink) {
      return (
        <Link
          id="user-profile-link"
          title={userIdentifier}
          className={className}
          {...redirectionProp}
        >
          {userIdentifier}
        </Link>
      );
    }

    if (profileAttribute) {
      return <Property type={profileAttribute.type} value={userIdentifier} />;
    }

    return userIdentifier;
  }
}

UserSampleTableUserCell.propTypes = {
  className: PropTypes.string,
  userId: PropTypes.string.isRequired,
  userProfile: PropTypes.object,
  profileAttributeColumn: PropTypes.string,
  onLoad: PropTypes.func,
  onShowModal: PropTypes.func,
  hasUserProfile: PropTypes.bool,
  renderAsLink: PropTypes.bool,
  profileAttribute: PropTypes.object,
};

UserSampleTableUserCell.defaultProps = {
  profileAttributeColumn: 'user_id',
  renderAsLink: false,
};

const mapStateToProps = (state, { userId }) => ({
  userProfile: selectAccountUserProfile(state, userId),
  hasUserProfile: selectAccountFeature(state, USER_PROFILE),
});

const mapDispatchToProps = {
  onLoad: fetchUserProfile,
  onShowModal: showModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserSampleTableUserCell);
